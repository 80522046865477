import React from 'react';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';
import PageLayoutHeader from '../pages/common/Headers/PageLayoutHeader';
import Alert, { AlertHeading } from '../components/bootstrap/Alert';
import { getRequester } from '../helpers/helpers';

const Dashboard = () => {
	const { person_name } = getRequester();

	return (
		<PageWrapper title='Dashboard'>
			<PageLayoutHeader />
			<Page container='fluid'>
				{/* DEFAULT */}
				<div className='row'>
					<div className='col-12'>
						<Alert
							icon='Verified'
							isLight
							color='success'
							rounded={2}
							borderWidth={0}
							className='shadow-3d-primary'>
							<AlertHeading tag='h2' className='h4'>
								Welcome {person_name}
							</AlertHeading>
							<span>Simplicity your work by using our apps!</span>
						</Alert>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Dashboard;
