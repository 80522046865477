import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
import { useFormik } from 'formik';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';
import Card, { CardBody } from '../components/bootstrap/Card';
import FormGroup from '../components/bootstrap/forms/FormGroup';
import Input from '../components/bootstrap/forms/Input';
import Button from '../components/bootstrap/Button';
import LogoLNK from '../components/LogoLNK';
import useDarkMode from '../hooks/useDarkMode';
import AuthModule from '../modules/AuthModule';
import Package from '../../package.json';
import Tooltips from '../components/bootstrap/Tooltips';
import Spinner from '../components/bootstrap/Spinner';

const validate = (values) => {
	const errors = {};
	if (!values.username || values.username === '') {
		errors.username = 'Required';
	}
	if (!values.password || values.password === '') {
		errors.password = 'Required';
	}
	return errors;
};
// eslint-disable-next-line react/prop-types
const LoginHeader = () => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const accessToken = localStorage.getItem('accessToken');
const appToken = localStorage.getItem('appToken');
const localName = process.env.REACT_APP_NAME;
const decodeToken = appToken ? jwt_decode(appToken) : null;

const LoginVendor = ({ isLoggedIn }) => {
	const [showPassword, setShowPassword] = useState(false);

	const [status, setStatus] = useState({
		loading: false,
		isLoggedIn,
		token: null,
		username: '',
		password: '',
	});

	const [loadingLogin, setLoadingLogin] = useState(false);

	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();

	// eslint-disable-line react/prop-types
	useEffect(() => {
		const fetchLogin = () => {
			if (!status.isLoggedIn && !status.loading && !accessToken) {
				return AuthModule.generateToken();
			}
			const parseAccessToken = accessToken && JSON.parse(accessToken);
			if (parseAccessToken && decodeToken && decodeToken.name === localName) {
				navigate('/home');
			}
			return {};
		};
		fetchLogin();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const handleLogin = (values) => {
		if (values.username && values.password) {
			status.loading = true;
			setStatus(status);
			setLoadingLogin(true);
			AuthModule.login(values.username.toUpperCase(), values.password)
				.then(async (res) => {
					if (res.accessToken) {
						const verifyToken = jwt_decode(res.accessToken);
						localStorage.setItem('accessToken', JSON.stringify(res));
						let role = 'Guest User';
						if (verifyToken.details.hris_org_tree.current_person) {
							role = ''.concat(
								verifyToken.details.hris_org_tree.current_person.nama_department,
								' ',
								verifyToken.details.hris_org_tree.current_person.nama_posisi,
							);
							localStorage.setItem('roles', JSON.stringify(role));

							// get menu
							await AuthModule.getMenu(role).then(() => {
								window.location.reload();
							});
						}
					}

					status.loading = false;
					setStatus(status);
				})
				.catch((err) => {
					status.loading = false;
					setStatus(status);
					Swal.fire({
						heightAuto: false,
						title: 'Warning!',
						text: err,
						icon: 'info',
					});
				})
				.finally(() => {
					setLoadingLogin(false);
				});
		}
	};

	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
		},
		validate,
		onSubmit: (values) => {
			// eslint-disable-next-line no-alert
			handleLogin(values);
		},
	});

	return (
		<PageWrapper title='Login' className={classNames('bg-info')}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card
							className='shadow-3d-dark'
							data-tour='login-page'
							stretch
							tag='form'
							noValidate
							onSubmit={formik.handleSubmit}>
							<CardBody>
								<div className='text-center my-2'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<LogoLNK width={200} />
									</Link>
								</div>
								<LoginHeader />
								<div className='col-12 mb-3'>
									<FormGroup
										id='username'
										autoComplete='off'
										isFloating
										label='Username (Vendor Code)'>
										<Input
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											name='username'
											className='uppercase'
											isValid={formik.isValid}
											invalidFeedback={formik.errors.username}
											value={formik.values.username}
											isTouched={formik.touched.username}
											readOnly={status.loading}
										/>
									</FormGroup>
								</div>
								<div className={classNames('col-12', 'mb-3', 'd-flex')}>
									<div className={classNames('flex-grow-1')}>
										<FormGroup
											id='password'
											isFloating
											autoComplete='off'
											label='Password (Token)'>
											<Input
												type={showPassword ? 'text' : 'password'}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												name='password'
												isValid={formik.isValid}
												invalidFeedback={formik.errors.password}
												value={formik.values.password}
												isTouched={formik.touched.password}
												readOnly={status.loading}
											/>
										</FormGroup>
									</div>
									<div>
										<Tooltips
											title={showPassword ? 'Hide' : 'Show'}
											placement='top'>
											<Button
												icon={showPassword ? 'eye_slash' : 'eye'}
												type='button'
												size='lg'
												color='light'
												className={classNames('ms-1')}
												onClick={() => setShowPassword(!showPassword)}
											/>
										</Tooltips>
									</div>
								</div>
								<div className='col-12'>
									<Button
										type={loadingLogin ? 'button' : 'submit'}
										color='warning'
										className={classNames(
											'w-100',
											'py-3',
											'd-flex',
											'justify-content-center',
											'align-items-center',
										)}>
										{loadingLogin && (
											<Spinner
												isGrow
												isSmall
												inButton='onlyIcon'
												className={classNames('me-2')}
											/>
										)}
										Login
									</Button>
								</div>
							</CardBody>
						</Card>
						{/* PP & TOU */}
						<div className={classNames('text-center', 'py-2')}>
							<a
								href='/privacy-policy'
								className={classNames('link-light text-decoration-none mx-2')}>
								Privacy policy
							</a>
							<a
								href='/terms-and-conditions'
								className={classNames('link-light text-decoration-none mx-2')}>
								Terms of use
							</a>
						</div>
						{/* VERSION */}
						<div
							className={classNames('text-center', 'py-2')}
							style={{ color: 'white' }}>
							Copyright © {Package.release_year} - Release {Package.production_date}
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

LoginVendor.propTypes = {
	isLoggedIn: PropTypes.bool,
};
LoginVendor.defaultProps = {
	isLoggedIn: false,
};

export default LoginVendor;
