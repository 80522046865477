import React from 'react';
import classNames from 'classnames';
import Header from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import { getRequester } from '../../../helpers/helpers';

const PageLayoutHeader = () => {
	const { person_name } = getRequester();

	return (
		<Header>
			<div className={classNames('header-left', 'col-md')}>
				<div className='d-flex flex-col align-items-center'>
					{process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== 'production' && (
						<div className='blink'>{process.env.REACT_APP_ENV.toUpperCase()}</div>
					)}
					<h4>Hi, {person_name}!</h4>
				</div>
			</div>
			<CommonHeaderRight />
		</Header>
	);
};

export default PageLayoutHeader;
