import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/ExampleCRUD')),
};

const Dashboard = {
	HSE: lazy(() => import('../views/afif/dashboard/DashboardHSE')),
};

const WorkPermit = {
	PermitApproval: lazy(() => import('../views/WorkPermit/PermitApproval')),
	PermitList: lazy(() => import('../views/WorkPermit/PermitList')),
	PermitRequest: lazy(() => import('../views/WorkPermit/PermitRequest')),
	PermitVendorRequest: lazy(() => import('../views/WorkPermit/PermitVendorRequest')),
	PermitListVendor: lazy(() => import('../views/WorkPermit/PermitListVendor')),
};

const MasterData = {
	DataCompany: lazy(() => import('../views/MasterData/DataCompany')),
	DataIdentification: lazy(() => import('../views/MasterData/DataIdentification')),
	DataWorkPermit: lazy(() => import('../views/MasterData/DataWorkPermit')),
	DataProject: lazy(() => import('../views/afif/master/DataProject')),
};

const contents = [
	// DUMMY
	{
		path: '/page-testing/crud',
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},
	// WORK PERMIT
	{
		path: '/work-permit/approval',
		element: <WorkPermit.PermitApproval />,
		index: 'PermitApproval',
		exact: true,
	},
	{
		path: '/work-permit/permit-list-vendor',
		element: <WorkPermit.PermitListVendor />,
		index: 'PermitListVendor',
		exact: true,
	},
	{
		path: '/work-permit/list',
		element: <WorkPermit.PermitList />,
		index: 'PermitList',
		exact: true,
	},
	{
		path: '/work-permit/request',
		element: <WorkPermit.PermitRequest />,
		index: 'PermitRequest',
		exact: true,
	},
	{
		path: '/work-permit/request-vendor',
		element: <WorkPermit.PermitVendorRequest />,
		index: 'PermitRequestVendor',
		exact: true,
	},
	// MASTER DATA
	{
		path: '/master-data/company',
		element: <MasterData.DataCompany />,
		index: 'DataCompany',
		exact: true,
	},
	{
		path: '/master-data/identification',
		element: <MasterData.DataIdentification />,
		index: 'DataIdentification',
		exact: true,
	},
	{
		path: '/master-data/work-permit',
		element: <MasterData.DataWorkPermit />,
		index: 'DataWorkPermit',
		exact: true,
	},
	// AFIF
	{
		path: '/master-data/project',
		element: <MasterData.DataProject />,
		index: 'DataProject',
		exact: true,
	},
	{
		path: '/dashboard/hse',
		element: <Dashboard.HSE />,
		index: 'DashboardHSE',
		exact: true,
	},
];
export default contents;
